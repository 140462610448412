<template>
  <div class="d-flex justify-center mt-8">
    <v-card
      ref="card"
      elevation="0"
      class="w-full rounded-xl"
      style="max-width: 600px"
    >
      <div style="position: relative">
        <v-img
          width="100%"
          class="rounded-t-xl rounded-b-0"
          :src="party?.cover"
          :aspect-ratio="16 / 9"
        >
          <template v-slot:placeholder>
            <v-card elevation="0" class="h-full">
              <v-skeleton-loader
                type="image"
                class="rounded-t-lg h-100"
                height="100%"
              />
            </v-card>
          </template>
        </v-img>
      </div>

      <v-card-text class="d-flex flex-column" style="gap: 0.5rem">
        <v-card outlined class="pa-4" rounded="lg">
          <div v-if="loading">
            <v-skeleton-loader
              height="20"
              width="160"
              type="heading"
              class="mb-1"
            />
            <v-skeleton-loader
              height="25"
              width="130"
              type="heading"
              class="mb-1"
            />
          </div>
          <div v-else @click="goToParty" style="cursor: pointer">
            <div class="d-flex justify-space-between">
              <p
                class="mb-0 text-overline font-weight-bold"
                style="line-height: 1em"
              >
                Pedido #{{ order?.id | shortId }}
              </p>
              <v-chip
                class="flex-shrink-0"
                label
                x-small
                :color="ticketStatusLabel[order.Payment[0]?.status].color"
              >
                <v-icon x-small left>
                  {{ ticketStatusLabel[order.Payment[0]?.status].icon }}
                </v-icon>
                {{ ticketStatusLabel[order.Payment[0]?.status].text }}
              </v-chip>
            </div>
            <h5>
              {{ party?.name }}
            </h5>
          </div>
          <v-skeleton-loader
            v-if="loading"
            height="18"
            width="175"
            type="heading"
            class="mb-0 mt-2"
          />

          <v-row dense v-if="loading" class="mx-0 mt-2">
            <v-col cols="6" v-for="i in 2" :key="i">
              <v-skeleton-loader
                type="image"
                class="rounded h-100"
                height="36"
              />
            </v-col>
          </v-row>
          <template v-if="payment">
            <v-btn
              v-if="isNotPayed(payment)"
              color="warning"
              @click="pay"
              block
              class="mt-4"
              :disabled="loading"
            >
              Pagar
            </v-btn>
            <template v-else-if="payment?.status === 'succeeded'">
              <v-alert dense text class="mb-3" icon="mdi-information">
                Os produtos adquiridos nesse pedido serão retirados no evento. 
              </v-alert>
              <v-btn block depressed color="primary" @click="openQrCode">
                <v-icon left>mdi-qrcode</v-icon>
                Ver retirada
              </v-btn>
            </template>
          </template>
        </v-card>

        <!-- Produtos -->
        <div v-if="order">
          <h5 class="mb-0">Produtos</h5>
          <v-row dense class="mx-1">
            <v-col
              v-for="item in order.Items"
              :key="item.id"
              cols="4"
              sm="3"
              style="position: relative"
            >
              <div>
                <v-img
                  :src="
                    item?.Variant?.Images[0]?.url ||
                    item?.Variant?.Product?.image
                  "
                  :aspect-ratio="1"
                  class="rounded-lg"
                />
                <v-chip style="position: absolute; top: 0; right: 0">
                  x{{ item.quantity }}
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </div>

        <!-- Pagamento -->
        <div v-if="order?.Payment[0]">
          <h5 class="">Pagamento</h5>

          <payment-status
            class="mt-2"
            @click="pay"
            :payment="order?.Payment[0]"
          />
        </div>
      </v-card-text>
    </v-card>
    <product-order-qr-code-view v-if="order" :order="order" />
  </div>
</template>

<script>
import moment from "moment";
import PRODUCT from "@/services/app/product";
import Payment from "@/utils/payment";

import PaymentStatus from "../../../components/global/PaymentStatus.vue";
import ProductOrderQrCodeView from "../../../components/app/modals/ProductOrderQrCodeView.vue";

export default {
  metaInfo() {
    return {
      title:
        "Pedidos " +
          (this.payment &&
            `#${this.$options.filters.shortId(this.payment?.id || "")} - `) ||
        "" + (this.party?.name || ""),
    };
  },
  components: {
    PaymentStatus,
    ProductOrderQrCodeView,
  },
  data: () => ({
    loading: true,
    order: null,
    paymentType: Payment.paymentType,
    paymentMethod: Payment.paymentMethod,
    ticketStatusLabel: Payment.ticketStatus,
    ticketStatusColor: {
      valid: "success",
      invalid: "error",
      pending: "warning",
    },
  }),
  methods: {
    openQrCode() {
      this.$emit("order-qrcode");
    },
    goToParty() {
      this.$router.push({
        name: "shop.party",
        params: {
          orgSlug: this.party.Organization.slug,
          partyId: this.party.slug || this.party.id,
        },
      });
    },
    pay() {
      this.$router.push({
        name: "app.payment.details",
        params: { id: this.payment.id },
        hash: "#pay",
      });
    },
    isNotPayed(payment) {
      if (!payment) return false;
      return [
        "pending",
        "requires_payment_method",
        "requires_confirmation",
        "requires_action",
        "processing",
        "requires_capture",
        "rejected",
      ].includes(payment?.status);
    },
    async getOrder(updating = false) {
      try {
        if (!updating) this.loading = true;
        const orderId = this.$route.params.id;
        const response = await PRODUCT.getOrderDetails(orderId);
        if (!response) throw new Error("Pedido não encontrado");

        this.order = response.order;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$router.push({ name: "app.products" });
      }
    },
  },
  computed: {
    party() {
      return this.order?.Party;
    },
    payment() {
      return this.order?.Payment[0];
    },
  },
  async mounted() {
    await this.getOrder();
  },
};
</script>
