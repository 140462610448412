<template>
  <div>
    <v-dialog
      v-model="dialogTerms"
      max-width="500px"
      :persistent="loading"
      @click:outside="close"
      style="overflow: hidden"
    >
      <v-card style="overflow: hidden">
        <v-card-text class="pt-4 pb-0">
          <div class="d-flex flex-column align-center justify-center">
            <h4>Atenção</h4>
          </div>
          <v-alert type="info" text class="text-center">
            Você tem certeza que deseja transferir esse ticket para o número
            {{ whatsapp }}?
          </v-alert>

          <p class="text-16 font-weight-bold mb-0">
            Sobre os termos de transferência:
          </p>
          <p>
            A transferência de ingressos é uma ação irreversível e não poderá
            ser desfeita. Leia atentamente os termos de transferência antes de
            confirmar a ação.
          </p>
          <p class="text-16 font-weight-bold mb-0">
            Sobre a transferência de ingressos:
          </p>
          <ul>
            <li>Proibido transferir ingressos para menores de 12 anos.</li>
            <li>
              O novo proprietário precisará criar uma conta para acessar o
              ingresso.
            </li>
            <li>
              Após a confirmação, a transferência não poderá ser revertida.
            </li>
            <li>
              O novo proprietário terá acesso total ao ingresso e a todos os
              seus benefícios.
            </li>
            <li>
              O novo proprietário não terá direito a reembolso, pois não foi ele
              quem realizou o pagamento.
            </li>
            <li
              v-if="
                ticket?.remainingTransfers === ticket?.maxTransfers &&
                ticket?.Payment?.paymentMethod === 'POS'
              "
            >
              Ingresso físico será cancelado no momento da transferência.
            </li>
            <li v-else>
              Após a transferência, o código do ingresso será alterado e o atual
              ficará inutilizavel.
            </li>
          </ul>

          <v-checkbox
            v-model="check"
            label="Eu aceito os termos de transferência."
            :disabled="loading"
            class="mb-2"
            hide-details
          />
        </v-card-text>

        <v-card-actions class="d-flex flex-column">
          <v-btn
            color="primary"
            @click.prevent="resolve"
            block
            :disabled="!check || loading"
            :loading="loading"
            class="mb-2"
          >
            Confirmar Transferência
          </v-btn>
          <v-btn text @click="close" :disabled="loading" block>
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TICKET from "@/services/app/ticket";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialogTerms: false,
      loading: false,
      ticket: null,
      check: false,
      promise: null,
    };
  },
  props: {
    whatsapp: {
      type: String,
    },
  },
  mounted() {
    this.$root.$on("confirm-transfer", this.open);
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    resolve() {
      this.promise(true);
      this.promise = null;
      this.close();
    },
    open(ticket) {
      const promise = new Promise((resolve) => {
        this.promise = resolve;
      });
      this.dialogTerms = true;
      this.ticket = ticket;
      this.error = false;
      this.check = false;
      this.succeeded = false;
      return promise;
    },
    close() {
      if (this.promise) {
        this.promise(false);
        this.promise = null;
      }
      this.dialogTerms = false;
      this.$emit("close");
      this.error = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
