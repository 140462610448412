<template>
  <v-card rounded="xl">
    <v-img v-if="item.party" :src="item.party.cover" :aspect-ratio="16 / 9" />
    <v-card-title
      :class="{
        'd-block flex-column': item.party,
        'd-flex align-center gap-2': !item.party,
      }"
      style="word-break: normal; line-height: 1.25rem"
    >
      <template v-if="item.party">
        <small class="text-overline lh-1">Produtos do evento</small>
        <h6 class="font-weight-bold text-18 mb-0">{{ item.party.name }}</h6>
      </template>
      <template v-else>
        <h6 class="font-weight-bold text-18 align-center mb-0">Pedido</h6>
        <span
          class="text-overline font-weight-bold lh-1"
          style="font-size: 14px !important"
        >
          #{{ item.orders[0].id | shortId }}
        </span>
      </template>
    </v-card-title>
    <v-card-text>
      <order-items v-if="!item.party" :item="item.order" />
      <div v-else outlined class="px-1">
        <b>
          Seu{{ item.orders.length > 1 ? "s" : "" }} pedido{{
            item.orders.length > 1 ? "s" : ""
          }}
        </b>
        <div class="d-flex flex-column gap-3">
          <v-card
            v-for="order in item.orders"
            :key="order.id"
            class="px-3 py-2"
            outlined
            rounded="lg"
          >
            <order-items :item="order" />
            <v-btn
              color="primary"
              block
              small
              text
              class="mx-2"
              @click="showOrderDetails(order)"
            >
              Detalhes
            </v-btn>
          </v-card>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import OrderItems from "./OrderItems.vue";
export default {
  components: { OrderItems },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    showOrderDetails(order) {
      this.$router.push({
        name: "app.products.details",
        params: { id: order.id },
      });
    },
    },

};
</script>

<style>
</style>