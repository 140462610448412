<template>
  <div class="text-center pa-4 pb-2">
    <div class="d-flex flex-column gap-2" v-if="!qrCode">
      <v-img
        src="@/assets/images/brands/pix.svg"
        width="210"
        height="120"
        contain
        class="mx-auto my-6"
      />
      <v-alert color="success" dense text border="bottom" class="mb-0 mt-2">
        <v-icon size="16" class="mr-2" color="success">mdi-lock</v-icon>
        Pague com Pix de forma rápida e segura.
      </v-alert>
      <!-- <v-icon size="100" class="py-8">mdi-qrcode-scan</v-icon> -->

      <v-btn color="primary" @click="pay" :loading="loading">
        Pagar com PIX • {{ payment.amount | currency(true) }}
      </v-btn>
      <small class="text--secondary wrap-balance">
        Não será possível alterar o método de pagamento após a confirmação.
      </small>
    </div>
    <template v-else-if="payment.status != 'succeeded'">
      <div class="d-flex justify-center">
        <qrcode-vue
          :value="qrCode.text"
          style="
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=);
            background-size: cover;
          "
          class="d-flex justify-center pa-4 white rounded my-6"
          size="230"
          level="M"
        />
      </div>

      <v-text-field
        v-model="qrCode.text"
        outlined
        dense
        readonly
        label="PIX copia e cola"
        class="text-center"
        :color="(copied && 'success') || undefined"
        hide-details
        @focus="selectAll"
      >
        <template v-slot:append>
          <v-tooltip top right>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                icon
                v-on="on"
                @click="copy"
                :color="(copied && 'success') || undefined"
              >
                <v-icon small>
                  {{ copied ? "mdi-check" : "mdi-content-copy" }}
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ copied ? "Copiado" : "Copiar" }}
            </span>
          </v-tooltip>
        </template>
      </v-text-field>

      <small>
        Escaneie o QR Code com o aplicativo do seu banco para efetuar o
        pagamento.
      </small>
    </template>
    <v-fab-transition v-else>
      <div class="pa-4">
        <v-icon size="60" color="success"> mdi-check-circle </v-icon>
        <h4 class="mt-2">Pagamento Aprovado</h4>
      </div>
    </v-fab-transition>
    <div class="d-flex justify-center mt-2">
      <v-btn text @click="close" small :disabled="loading"> Fechar </v-btn>
    </div>
  </div>
</template>

<script>
import TICKET from "@/services/app/ticket";
import QrcodeVue from "qrcode.vue";

export default {
  components: { QrcodeVue },
  data: () => ({
    loading: false,
    qrCode: false,
    copied: false,
  }),
  methods: {
    async pay() {
      try {
        this.loading = true;
        const { data } = await TICKET.pay(this.paymentId, {
          type: "PIX",
        });

        this.qrCode = data.qrCode;
        this.$emit("lock", "PIX");
        this.$emit("update");
        this.updateInterval();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    copy() {
      if (!this.qrCode || this.copied) return;
      navigator.clipboard.writeText(this.qrCode.text);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
    updateInterval() {
      if (this.interval) clearInterval(this.interval);
      if (this.payment.status === "succeeded") return;
      this.interval = setInterval(() => {
        this.$emit("update");
      }, 15000);
    },
    close() {
      this.$emit("close");
    },
    selectAll(e) {
      e.target.select();
    },
  },
  watch: {
    payment(val, old) {
      if (val.status === "succeeded" && old.status !== "succeeded") {
        this.$emit("refresh");

        this.$root.$emit("purchase", {
          amount: this.payment.amount,
          Tickets: this.tickets,
        });

        clearInterval(this.interval);
        this.$confetti.start();
        setTimeout(() => {
          this.$confetti.stop();
        }, 5000);
      }
    },
  },

  mounted() {
    if (this.payment.paymentType !== "PIX") return;
    this.qrCode = this.integrationData.qrCode;
    this.updateInterval();
  },
  props: {
    paymentId: {
      type: String,
      required: true,
    },
    payment: {
      type: Object,
      required: true,
    },
    tickets: {
      type: Array,
      required: true,
    },
    integrationData: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style>
</style>