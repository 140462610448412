<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
    @click:outside="close"
    :persistent="loading"
  >
    <v-card v-if="!!party" style="overflow: hidden">
      <v-card-text class="pb-2 pt-6" v-if="!succeeded">
        <!-- <v-img :src="party.cover" :aspect-ratio="16 / 9" class="rounded" /> -->

        <h5 class="text-center">O que achou do evento {{ party.name }}?</h5>
        <v-rating v-model="rating.value" class="mt-4 text-center" large />
        <v-textarea
          v-model="rating.comment"
          label="Comentário"
          class="mt-4"
          outlined
          rows="3"
          :counter="255"
        />
      </v-card-text>
      <v-card-text class="pb-2 pt-6" v-else>
        <div class="text-center">
          <v-icon x-large color="success">mdi-check-circle</v-icon>
          <h5>Obrigado por avaliar o evento {{ party.name }}!</h5>
        </div>
      </v-card-text>

      <v-card-actions class="d-flex flex-column">
        <v-btn
          v-if="!succeeded"
          color="primary"
          @click="save"
          block
          :disabled="!isValid"
          :loading="loading"
          class="mb-2"
        >
          Enviar
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="close"
          :disabled="loading"
          block
          class="ml-0"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RATING from "@/services/app/rating";
import { mapGetters } from "vuex";
export default {
  data: () => ({
    dialog: false,
    loading: false,
    party: null,
    rating: {
      value: 0,
      comment: "",
    },
    succeeded: false,
  }),
  methods: {
    async save() {
      try {
        this.loading = true;
        this.error = false;
        await RATING.create({
          partyId: this.party.id,
          value: this.rating.value,
          comment: this.rating.comment,
        });
        this.succeeded = true;
        this.$emit("rated");
      } catch (error) {
        this.error = error.message || "Erro ao avaliar evento";
      } finally {
        this.loading = false;
      }
    },
    open({ party, succeeded = false, value = 0 }) {
      this.party = party;
      this.succeeded = succeeded;
      this.error = false;
      this.rating = {
        value,
        comment: "",
      };
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.dialog = false;
      this.$emit("close");
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    isValid() {
      return !!this.rating.value;
    },
  },

  mounted() {
    this.$parent.$on("rating", this.open);
  },
};
</script>

<style></style>
