<template>
  <div class="pt-4">
    <h4 :class="{ 'white--text': $vuetify.breakpoint.smAndDown }">
      Meus produtos
    </h4>
    <div class="px-2" v-if="loading">
      <v-progress-linear indeterminate />
    </div>
    <cols-organizer
      v-if="orders.length"
      :items="grouppedByParty"
      :cols="1"
      :sm="2"
      :lg="2"
    >
      <template v-slot:default="{ item }">
        <my-party-orders :item="item" />
      </template>
    </cols-organizer>

    <div
      v-else-if="!loading"
      style="height: 50vh"
      class="d-flex flex-column justify-center"
    >
      <div class="justify-center d-flex">
        <v-avatar size="75" color="grey lighten-3">
          <v-icon x-large color="grey darken-2"> mdi-shopping </v-icon>
        </v-avatar>
      </div>
      <p class="text-center text-subtitle-1 mb-6 mt-1">
        Você não possui produtos
      </p>
    </div>

    <v-alert
      v-if="error"
      border="left"
      class="text-left"
      text
      icon="mdi-alert-circle-outline"
      type="error"
    >
      {{ error }}
    </v-alert>
  </div>
</template>

<script>
import PRODUCT from "@/services/app/product";
import ColsOrganizer from "../../../components/global/ColsOrganizer.vue";
import MyPartyOrders from "../../../components/app/myProducts/MyPartyOrders.vue";

export default {
  metaInfo: {
    title: "Meus Produtos",
  },
  components: {
    ColsOrganizer,
    MyPartyOrders,
  },
  data: () => ({
    loading: true,
    error: null,
    orders: [],
  }),
  methods: {
    async getOrders() {
      try {
        this.loading = true;
        const { orders } = await PRODUCT.getOrders();
        this.orders = orders;
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    grouppedByParty() {
      const orders = this.orders.reduce((acc, order) => {
        const id = order.Party.id || order.id;

        if (order.Party) {
          if (!acc[id]) acc[id] = { party: order.Party, orders: [] };
          acc[id].orders.push(order);
        } else {
          acc[id] = { order: order.id, party: null };
        }
        return acc;
      }, {});
      return Object.values(orders);
    },
  },
  mounted() {
    this.getOrders();
  },
};
</script>

<style>
</style>