<template>
  <div class="text-center pa-4 pb-2">
    <div class="d-flex flex-column gap-2" v-if="!qrCode">
      <!-- <v-icon size="80" class="pt-0">mdi-wallet</v-icon> -->
      <h6 class="mb-0">Pagamento via QR Code</h6>
      <p>Finalize no seu app de banco ou carteira digital</p>

      <!-- <v-alert color="success" dense text border="bottom">
        <v-icon size="16" class="mr-2" color="success">mdi-lock</v-icon>
      </v-alert> -->

      <v-card outlined class="pa-3 pb-1 rounded-lg mb-4">
        <p class="font-weight-bold mb-0 lh-3">
          Compatível com os principais bancos e carteiras digitais
        </p>
        <v-card
          class="d-flex flex-wrap gap-2 mt-1 justify-center"
          color="transparent"
          elevation="0"
        >
          <img
            v-for="(icon, i) in icons"
            :key="i"
            :src="paymentMethodsIcons[icon]"
            :alt="icon"
            width="52px"
            height="36px"
            class="unselectable non-draggable white--text"
          />
        </v-card>

        <div class="mt-1">
          <small class="wrap-balance text--secondary pt-10 lh-1">
            Não é pagamento por PIX.
          </small>
        </div>
      </v-card>
      <!-- <v-icon size="100" class="py-8">mdi-qrcode-scan</v-icon> -->

      <v-btn color="primary" @click="pay" :loading="loading">
        Pagar • {{ payment.amount | currency(true) }}
      </v-btn>
      <small class="wrap-balance text--secondary">
        <!-- Não é pagamento por PIX.  -->
        Não será possível alterar o método de pagamento após a confirmação.
      </small>
    </div>
    <template v-else-if="payment.status != 'succeeded'">
      <div class="d-flex justify-center">
        <qrcode-vue
          :value="qrCode"
          style="
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=);
            background-size: cover;
          "
          class="d-flex justify-center pa-4 white rounded"
          size="230"
          level="M"
        />
      </div>

      <small>
        Escaneie o QR Code com o aplicativo do seu banco para efetuar o
        pagamento.
      </small>

      <!-- Compatible banks -->
      <v-card outlined class="pa-3 pb-1 rounded-lg my-4">
        <p class="font-weight-bold mb-0 lh-3">
          Compatível com os principais bancos e carteiras digitais
        </p>
        <v-card
          class="d-flex flex-wrap gap-2 mt-1 justify-center"
          color="transparent"
          elevation="0"
        >
          <img
            v-for="(icon, i) in icons"
            :key="i"
            :src="paymentMethodsIcons[icon]"
            :alt="icon"
            width="52px"
            height="36px"
            class="unselectable non-draggable white--text"
          />
        </v-card>

        <div class="mt-1">
          <small class="wrap-balance text--secondary pt-10 lh-1">
            Não é pagamento por PIX.
          </small>
        </div>
      </v-card>
    </template>
    <v-fab-transition v-else>
      <div class="pa-4">
        <v-icon size="60" color="success"> mdi-check-circle </v-icon>
        <h4 class="mt-2">Pagamento Aprovado</h4>
      </div>
    </v-fab-transition>
    <div class="d-flex justify-center mt-2">
      <v-btn text @click="close" small :disabled="loading"> Fechar </v-btn>
    </div>
  </div>
</template>

<script>
const icons = require.context("@/assets/images/payment-card/", false, /\.svg$/);
import TICKET from "@/services/app/ticket";
import QrcodeVue from "qrcode.vue";

export default {
  components: { QrcodeVue },
  data: () => ({
    loading: false,
    qrCode: false,
    copied: false,
    icons: ["picpay", "mp", "bb", "bradesco", "caixa", "next"],
  }),
  methods: {
    async pay() {
      try {
        this.loading = true;
        const { data } = await TICKET.pay(this.paymentId, {
          type: "QR_CODE",
        });

        this.qrCode = data.qrCode;
        this.$emit("lock", "QR_CODE");
        this.$emit("update");
        this.updateInterval();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    copy() {
      if (!this.qrCode || this.copied) return;
      navigator.clipboard.writeText(this.qrCode.text);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
    updateInterval() {
      if (this.interval) clearInterval(this.interval);
      if (this.payment.status === "succeeded") return;
      this.interval = setInterval(() => {
        this.$emit("update");
      }, 15000);
    },
    close() {
      this.$emit("close");
    },
    selectAll(e) {
      e.target.select();
    },
  },
  computed: {
    paymentMethodsIcons() {
      const iconsList = new Set(this.icons);
      return Object.fromEntries(
        Array.from(iconsList).map((icon) => [icon, icons(`./${icon}.svg`)])
      );
    },
  },
  watch: {
    payment(val, old) {
      if (val.status === "succeeded" && old.status !== "succeeded") {
        this.$emit("refresh");

        this.$root.$emit("purchase", {
          amount: this.payment.amount,
          Tickets: this.tickets,
        });

        clearInterval(this.interval);
        this.$confetti.start();
        setTimeout(() => {
          this.$confetti.stop();
        }, 5000);
      }
    },
  },

  mounted() {
    if (this.payment.paymentType !== "PIX") return;
    this.qrCode = this.integrationData.qrCode;
    this.updateInterval();
  },
  props: {
    paymentId: {
      type: String,
      required: true,
    },
    payment: {
      type: Object,
      required: true,
    },
    tickets: {
      type: Array,
      required: true,
    },
    integrationData: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style>
</style>