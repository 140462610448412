<template>
  <div class="mt-8">
    <div>
      <div style="position: relative">
        <v-row style="position: relative" class="mx-0" dense>
          <v-col cols="12" md="7" lg="8">
            <v-card elevation="0" rounded="xl">
              <v-img
                :src="artist?.coverPhoto"
                class="rounded-xl"
                style="height: 300px; margin-bottom: -36px"
                :style="{
                  height: $vuetify.breakpoint.smAndDown ? '200px' : '300px',
                }"
              >
                <template v-slot:placeholder>
                  <v-skeleton-loader type="image" height="300px" width="100%" />
                </template>
              </v-img>
            </v-card>
            <v-card
              class="rounded-xl pa-4"
              :class="$vuetify.breakpoint.smAndDown ? 'mx-4' : 'mx-8'"
            >
              <div
                class="d-flex align-center gap-3"
                style="margin-top: -36px; margin-left: -36px"
              >
                <v-avatar
                  size="115"
                  :color="$vuetify.theme.dark ? '#1e1e1e' : '#fff'"
                  :style="{
                    border: `5px solid ${
                      $vuetify.theme.dark ? '#1e1e1e' : '#fff'
                    }`,
                  }"
                >
                  <v-img :src="artist?.profilePhoto" alt="Profile Photo">
                    <template v-slot:placeholder>
                      <v-skeleton-loader
                        type="image"
                        height="115px"
                        width="115px"
                      />
                    </template>
                  </v-img>
                </v-avatar>
                <div class="mt-5" v-if="!loading">
                  <h4 class="mb-0 lh-8">{{ artist?.name }}</h4>
                  <span class="text-overline lh-1">{{ artist?.category }}</span>
                </div>
                <div v-else class="mt-5">
                  <v-skeleton-loader
                    type="image"
                    height="26px"
                    width="215px"
                    class="rounded-pill mb-2"
                  />
                  <v-skeleton-loader
                    type="image"
                    height="13px"
                    width="100px"
                    class="rounded-pill"
                  />
                </div>
              </div>
              <div
                v-if="!loading && artist?.description"
                class="d-flex flex-column mt-3"
              >
                <span class="text-16">{{ artist?.description }}</span>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="5" lg="4">
            <spotify-embed
              v-if="!loading"
              :id="artist?.spotifyId"
              class="w-full px-4"
            />
            <v-skeleton-loader
              v-else
              type="image"
              height="390px"
              width="100%"
              class="rounded-xl px-4"
            />
          </v-col>
        </v-row>
      </div>
      <template v-if="!loading">
        <div class="mx-2 mb-3 mt-8">
          <h4 class="mb-0 lh-3">Eventos</h4>
          <span>Veja todos os eventos do artista</span>
        </div>
        <cols-organizer :cols="1" :sm="2" :md="3" :items="shows">
          <template v-slot="{ item: results }">
            <party-item :party="results"></party-item>
          </template>
        </cols-organizer>
      </template>
    </div>
  </div>
</template>

<script>
import artistService from "@/services/app/artist";
import SHOP from "@/services/shop";
import ColsOrganizer from "@/components/global/ColsOrganizer.vue";
import PartyItem from "@/components/shop/PartyItem.vue";
import SpotifyEmbed from "@/components/global/SpotifyEmbed.vue";

export default {
  name: "ArtistView",
  metaInfo() {
    return {
      title: `${this.artist?.name || "Carregando"} | Artista`,
    };
  },
  components: {
    ColsOrganizer,
    PartyItem,
    SpotifyEmbed,
  },
  data() {
    return {
      artist: null,
      loading: false,
      shows: [],
      loading: false,
    };
  },
  methods: {
    async fetchArtist() {
      this.loading = true;
      try {
        const slug = this.$route.params.slug;
        this.artist = await artistService.getArtistBySlug(slug);
        this.loading = false;
      } catch (error) {
        this.$router.replace("/");
      }
    },
    async artistShows() {
      try {
        const response = await SHOP.search({
          artistId: this.artist.id,
        });
        this.shows = response.party;
      } catch (error) {
        console.error("Erro ao buscar shows:", error);
      }
    },
  },
  async created() {
    await this.fetchArtist();
    await this.artistShows();
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .artist-info {
    width: 90% !important;
  }
}
</style>
