<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    overlay-opacity="0.9"
    @click:outside="close"
    persistent
  >
    <v-card v-if="!!ticket && !succeeded" rounded="lg">
      <v-card-title class="justify-center" v-if="!transferDisabled">
        Transferir Ingresso
      </v-card-title>
      <v-card-text class="pb-2">
        <template v-if="transferDisabled">
          <div class="text-center pt-8">
            <v-avatar class="mb-4" size="60" color="grey lighten-4">
              <v-icon x-large color="grey darken-2">
                {{ transferDisabled.icon }}
              </v-icon>
            </v-avatar>
            <h6>{{ transferDisabled.text }}</h6>
          </div>
        </template>
        <template v-else>
          <phone-input
            :phone="whatsapp"
            @update:phone="(e) => (whatsapp = e)"
            :ddi="ddi"
            @update:ddi="(e) => (ddi = e)"
            @valid="(v) => (valid = v)"
            dense
            outlined
            label="WhatsApp do Destinatário"
            :disabled="loading"
          />
          <v-alert v-if="ticket?.remainingTransfers == 1" type="warning" text>
            Esse ingresso só pode ser transferido
            <b> mais uma vez.</b> Verifique o número do WhatsApp antes de
            realizar a transferência.
          </v-alert>
          <v-alert v-else type="warning" text>
            Verifique o número do WhatsApp antes de realizar a transferência.
          </v-alert>
          <!-- <v-checkbox
            class="my-0 pt-0"
            v-model="check"
            :disabled="loading"
            label="Confirmo que o WhatsApp do destinatário está correto."
            :rules="[(v) => !!v || 'Confirmação é obrigatória']"
          /> -->
          <v-alert type="error" v-if="error" class="mt-2 mb-0" border="left">
            {{ error }}
          </v-alert>
        </template>
      </v-card-text>
      <v-card-actions class="d-flex flex-column">
        <v-btn
          v-if="!transferDisabled"
          color="primary"
          @click.prevent="transfer"
          block
          :disabled="!valid || loading"
          :loading="loading"
          class="mb-2"
        >
          Transferir
        </v-btn>
        <v-btn text @click="close" :disabled="loading" block> Cancelar </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="succeeded">
      <v-card-text>
        <div class="text-center pt-8">
          <v-avatar class="mb-4" size="60" color="success lighten-4">
            <v-icon x-large color="success darken-2">mdi-check</v-icon>
          </v-avatar>
          <h6>Ingresso transferido com sucesso!</h6>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex flex-column">
        <v-btn
          color="primary"
          text
          @click="$router.push({ name: 'app.ticket' })"
          block
          class="ml-0"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
    <transfer-terms ref="terms" :whatsapp="whatsapp" />
  </v-dialog>
</template>

<script>
import moment from "moment";
import TICKET from "@/services/app/ticket";
import { mapGetters } from "vuex";
import PhoneInput from "../../global/PhoneInput.vue";
import TransferTerms from "./TransferTerms.vue";
export default {
  components: { PhoneInput, TransferTerms },
  data: () => ({
    dialog: false,
    ticket: null,
    loading: false,
    valid: false,
    error: false,
    whatsapp: "",
    ddi: 55,
    succeeded: false,
  }),
  methods: {
    open(ticket) {
      this.ticket = ticket;
      this.error = false;
      this.succeeded = false;
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.dialog = false;
      this.$emit("close");
      this.$nextTick(() => {
        this.whatsapp = "";
        this.ddi = 55;
        this.error = false;
      });
    },
    async transfer() {
      try {
        this.loading = true;
        this.error = false;
        const acceptTerms = await this.$refs.terms.open(this.ticket);
        if (!acceptTerms) {
          this.loading = false;
          return;
        }
        await TICKET.transfer(this.ticket.id, {
          user: { phone: this.whatsapp, ddi: this.ddi },
        });
        this.succeeded = true;
      } catch (error) {
        this.error = error.message || "Erro ao transferir ingresso";
        this.check = false;
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    transferDisabled() {
      if (!this.ticket) return { icon: "mdi-error", text: "Erro ao carregar" };
      if (
        this.ticket.Payment.paymentType === "COURTESY" &&
        !this.ticket.TicketBlock.TicketGroup.Party.allowCourtesyTransfer
      ) {
        return {
          icon: "mdi-account-star",
          text: "Ingresso cortesia não pode ser transferido",
        };
      }

      if (this.ticket.TicketEntry.length > 0) {
        return {
          icon: "mdi-account-check",
          text: "Ingresso já foi utilizado, não pode ser transferido",
        };
      }

      if (
        moment(this.ticket.TicketBlock.TicketGroup.Party.date).isBefore(
          moment()
        )
      )
        return {
          icon: "mdi-ticket",
          text: "O evento já ocorreu, ingresso não pode ser transferido",
        };

      const [_, hoursBefore, transferBefore] =
        this.ticket.TicketBlock.TicketGroup.Party.refundPolicy.split("-");
      const partyDate = moment(this.ticket.TicketBlock.TicketGroup.Party.date);
      const now = moment();
      if (partyDate.diff(now, "hours") < (transferBefore || hoursBefore))
        return {
          icon: "mdi-clock-alert",
          text: `O ingresso não pode ser transferido pois a política do evento não permite transferências ${
            transferBefore || hoursBefore
          } horas antes do início do evento`,
        };

      if (this.ticket?.remainingTransfers <= 0) {
        return {
          icon: "mdi-account-multiple",
          text: "Esse ingresso foi transferido o número máximo de vezes permitido, entre em contato com o suporte para mais informações",
        };
      }

      return false;
    },
  },
  watch: {
    whatsapp() {
      this.error = false;
    },
  },
  mounted() {
    this.$parent.$on("transfer", this.open);
  },
};
</script>

<style></style>
