<template>
  <div class="d-flex flex-column h-full">
    <div class="w-full">
      <img
        style="width: 100%; height: 140px; object-fit: cover"
        class="rounded-t-xl rounded-b-0"
        :src="images.croppedCover"
        :aspect-ratio="16 / 9"
      />

      <div class="d-flex px-6 mb-2 flex-column gap-1">
        <p
          style="font-size: 10px !important"
          class="text-overline lh-1 font-weight-medium mb-0 text--secondary"
        >
          {{
            ticket.TicketBlock.TicketGroup.Party.date | date("DD [de] MMM [de] YYYY [às] HH:mm")
          }}
        </p>
        <p class="text-body-1 lh-1 font-weight-black text-truncate mb-0">
          {{ ticket.TicketBlock.TicketGroup.Party?.name }}
        </p>
      </div>
    </div>
    <div class="pt-0 flex-grow-1 px-0">
      <div class="d-flex justify-center align-center">
        <div class="rounded" style="position: relative">
          <qrcode-vue
            style="
              background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=);
              background-size: cover;
            "
            class="d-flex justify-center pa-2 ma-1 white rounded"
            :value="qrCodeLink"
            size="160"
            level="H"
          />
          <div
            style="position: absolute; top: 0px; height: 100%; width: 100%"
            class="d-flex justify-center align-center"
          >
            <div class="white d-flex rounded-lg" style="padding: 2px">
              <img
                :src="images.favicon"
                contain
                style="width: 22px; height: 22px"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column align-center">
        <p class="text-overline mb-0 text-center font-weight-bold lh-1">
          {{ ticket?.TicketBlock.TicketGroup?.name }}
        </p>
        <p class="text-overline mb-0 text-center font-weight-bold lh-1">
          {{ ticket?.TicketBlock?.name }}
        </p>
        <p
          v-if="ticket.Table"
          style="font-size: 10px !important"
          class="text-overline lh-1 font-weight-bold mb-0 text--secondary text-truncate mt-1"
        >
          {{ ticket.Table.Group?.name }}
          •
          {{ ticket.Table?.name }}
        </p>
      </div>
    </div>
    <div class="mb-4">
      <v-alert color="info" class="mb-0 lh-1 mt-2 mx-6" text dense>
        O ingresso pertence a
        <span class="font-weight-bold">{{ user?.name }}</span> com CPF
        <span class="font-weight-bold">{{ obfuscatedCPF }}</span>
      </v-alert>
      <div class="d-flex gap-4 my-2 mx-6 justify-space-around">
        <span class="text--secondary text-12">
          Ingresso:
          <span style="font-size: 11px !important" class="text-overline lh-1">
            {{ ticket?.id | shortId }}
          </span>
        </span>
        <span class="text--secondary text-12">
          Pagamento:
          <span style="font-size: 11px !important" class="text-overline lh-1">
            {{ ticket?.Payment.id | shortId }}
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
export default {
  components: { QrcodeVue },
  props: {
    ticket: Object,
    currentIndex: Number,
    user: Object,
    images: Object,
  },
  data: () => ({
    qrCodeLink: "",
  }),
  mounted() {
    this.qrCodeLink = `${window.location.origin}/v/t/${this.ticket.code}`;
  },
  methods: {},
  computed: {
    obfuscatedCPF() {
      if (!this.user?.document) return "";
      return this.user.document
        .replace(/\D/g, "")
        .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.***.***-$4");
    },
  },
};
</script>

<style></style>
