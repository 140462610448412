async function sha256(message) {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}


async function generateDeviceFingerprint() {
  const userAgent = navigator.userAgent;
  const language = navigator.language || navigator.userLanguage;
  const platform = navigator.platform;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const screenResolution = `${window.screen.width}x${window.screen.height}`;
  const colorDepth = window.screen.colorDepth;
  const deviceMemory = navigator.deviceMemory || 'unknown';
  const hardwareConcurrency = navigator.hardwareConcurrency || 'unknown';

  // Captura de plugins (não suportado em todos os navegadores)
  let plugins = '';
  if (navigator.plugins) {
    plugins = Array.from(navigator.plugins).map(p => p.name).join(',');
  }

  // Captura de fontes de canvas para aumentar a unicidade
  let canvasFingerprint = '';
  try {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.textBaseline = 'top';
    ctx.font = '16px Arial';
    ctx.textBaseline = 'alphabetic';
    ctx.fillStyle = '#f60';
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = '#069';
    ctx.fillText('Hello, world!', 2, 15);
    ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
    ctx.fillText('Hello, world!', 4, 17);
    canvasFingerprint = canvas.toDataURL();
  } catch (e) {
    canvasFingerprint = 'not_supported';
  }

  // Combina todas as informações em uma única string
  const fingerprintData = [
    userAgent,
    language,
    platform,
    timezone,
    screenResolution,
    colorDepth,
    deviceMemory,
    hardwareConcurrency,
    plugins,
    canvasFingerprint
  ].join('###');

  // Gera o hash SHA-256 dos dados do fingerprint
  const fingerprintHash = await sha256(fingerprintData);

  return fingerprintHash;
}

export default generateDeviceFingerprint; 
