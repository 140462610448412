<template>
  <div>
    <b v-if="!hideTitle">
      Pedido
      <span class="text-overline font-weight-bold">
        #{{ item.id | shortId }}
      </span>
    </b>

    <!-- <pre>{{ item }}</pre> -->
    <v-row dense class="mx-1">
      <v-col
        v-for="item in item.Items"
        :key="item.id"
        cols="4"
        sm="3"
        md="2"
        style="position: relative"
      >
        <div>
          <v-img
            :src="
              item?.Variant?.Images[0]?.url || item?.Variant?.Product?.image
            "
            :aspect-ratio="1"
            class="rounded-lg"
          />
          <v-chip style="position: absolute; top: 0; right: 0">
            x{{ item.quantity }}
          </v-chip>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>