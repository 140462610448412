var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-8"},[_c('div',[_c('div',{staticStyle:{"position":"relative"}},[_c('v-row',{staticClass:"mx-0",staticStyle:{"position":"relative"},attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"7","lg":"8"}},[_c('v-card',{attrs:{"elevation":"0","rounded":"xl"}},[_c('v-img',{staticClass:"rounded-xl",staticStyle:{"height":"300px","margin-bottom":"-36px"},style:({
                height: _vm.$vuetify.breakpoint.smAndDown ? '200px' : '300px',
              }),attrs:{"src":_vm.artist?.coverPhoto},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"image","height":"300px","width":"100%"}})]},proxy:true}])})],1),_c('v-card',{staticClass:"rounded-xl pa-4",class:_vm.$vuetify.breakpoint.smAndDown ? 'mx-4' : 'mx-8'},[_c('div',{staticClass:"d-flex align-center gap-3",staticStyle:{"margin-top":"-36px","margin-left":"-36px"}},[_c('v-avatar',{style:({
                  border: `5px solid ${
                    _vm.$vuetify.theme.dark ? '#1e1e1e' : '#fff'
                  }`,
                }),attrs:{"size":"115","color":_vm.$vuetify.theme.dark ? '#1e1e1e' : '#fff'}},[_c('v-img',{attrs:{"src":_vm.artist?.profilePhoto,"alt":"Profile Photo"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"image","height":"115px","width":"115px"}})]},proxy:true}])})],1),(!_vm.loading)?_c('div',{staticClass:"mt-5"},[_c('h4',{staticClass:"mb-0 lh-8"},[_vm._v(_vm._s(_vm.artist?.name))]),_c('span',{staticClass:"text-overline lh-1"},[_vm._v(_vm._s(_vm.artist?.category))])]):_c('div',{staticClass:"mt-5"},[_c('v-skeleton-loader',{staticClass:"rounded-pill mb-2",attrs:{"type":"image","height":"26px","width":"215px"}}),_c('v-skeleton-loader',{staticClass:"rounded-pill",attrs:{"type":"image","height":"13px","width":"100px"}})],1)],1),(!_vm.loading && _vm.artist?.description)?_c('div',{staticClass:"d-flex flex-column mt-3"},[_c('span',{staticClass:"text-16"},[_vm._v(_vm._s(_vm.artist?.description))])]):_vm._e()])],1),_c('v-col',{attrs:{"cols":"12","md":"5","lg":"4"}},[(!_vm.loading)?_c('spotify-embed',{staticClass:"w-full px-4",attrs:{"id":_vm.artist?.spotifyId}}):_c('v-skeleton-loader',{staticClass:"rounded-xl px-4",attrs:{"type":"image","height":"390px","width":"100%"}})],1)],1)],1),(!_vm.loading)?[_vm._m(0),_c('cols-organizer',{attrs:{"cols":1,"sm":2,"md":3,"items":_vm.shows},scopedSlots:_vm._u([{key:"default",fn:function({ item: results }){return [_c('party-item',{attrs:{"party":results}})]}}],null,false,3927486354)})]:_vm._e()],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-2 mb-3 mt-8"},[_c('h4',{staticClass:"mb-0 lh-3"},[_vm._v("Eventos")]),_c('span',[_vm._v("Veja todos os eventos do artista")])])
}]

export { render, staticRenderFns }