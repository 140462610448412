<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
    overlay-opacity="0.95"
    persistent
    scrollable
    content-class="rounded-xl"
    style="overflow: hidden"
  >
    <v-card style="overflow: hidden" rounded="xl">
      <v-card-text class="px-0 pb-2 pt-6">
        <div class="w-full text-center">
          <p
            class="text-overline font-weight-bold mb-0 text--secondary text-truncate"
            style="line-height: 1em"
          >
            Pedido #{{ order?.id | shortId }} • {{ totalItems }} ite{{
              totalItems != 1 ? "ns" : "m"
            }}
          </p>
          <p class="text-body-1 font-weight-black text-truncate mb-0">
            {{ order.Party?.name }}
          </p>
        </div>
        <div class="d-flex justify-center align-center">
          <div class="rounded" style="position: relative">
            <qrcode-vue
              style="
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=);
                background-size: cover;
              "
              class="d-flex justify-center pa-5 ma-1 white rounded"
              :value="qrCodeLink"
              size="260"
              level="M"
            />
            <div
              style="position: absolute; top: 0px; height: 100%; width: 100%"
              class="d-flex justify-center align-center"
            >
              <div
                style="width: 36px; height: 36px; align-content: center"
                class="white rounded"
              >
                <v-img src="/favicon.png" contain height="36" />
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-space-around mx-8 lh-1 text-center py-2">
          <small>
            Pagamento<br />
            <b class="text-overline lh-1">
              #{{ order?.Payment[0]?.id | shortId }}
            </b>
          </small>
        </div>

        <v-alert color="info" class="mb-0 mt-2 mx-6" text dense border="bottom">
          O pedido pertence a
          <span class="font-weight-bold">{{ user?.name }}</span> com CPF
          <span class="font-weight-bold">{{ obfuscatedCPF }}</span>
        </v-alert>

        <v-alert type="secondary" text dense class="mx-6 mt-2">
          Os produtos poderão ser retirados no local do evento mediante a
          apresentação deste QR Code.
        </v-alert>

        <v-divider class="my-5 mx-4" />

        <div class="mx-6 d-flex flex-column gap-2">
          <v-card
            v-for="item of order.Items"
            :key="item.id"
            class="d-flex align-center gap-3"
            outlined
            rounded="lg"
          >
            <div>
              <v-img
                :src="
                  item?.Variant?.Images[0]?.url || item?.Variant?.Product?.image
                "
                :aspect-ratio="1"
                class="rounded-lg rounded-r-0"
                width="65"
                height="65"
              />
            </div>
            <div class="d-flex flex-column gap-1">
              <h6 class="mb-0 lh-2">
                {{ item?.Variant?.name }} • {{ item?.Variant?.Product?.name }}
              </h6>
              <span> {{ item.quantity }}x </span>
            </div>
          </v-card>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="close" block>Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import { mapGetters } from "vuex";
export default {
  components: { QrcodeVue },
  data: () => ({
    dialog: false,
  }),
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    obfuscatedCPF() {
      if (!this.user?.document) return "";
      return this.user.document
        .replace(/\D/g, "")
        .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.***.***-$4");
    },
    qrCodeLink() {
      return `${window.location.origin}/verify/order/${this.order.code}`;
    },
    totalItems() {
      return this.order?.Items.reduce((acc, item) => acc + item.quantity, 0);
    },
  },
  watch: {},
  props: {
    order: {
      type: Object,
      default: () => null,
    },
  },

  mounted() {
    this.$parent.$on("order-qrcode", this.open);
  },
};
</script>

<style></style>
