<template></template>

<script>
const eventProcessor = {
  add_to_cart: ({ ticketBlock, ticketGroup, kit }) => ({
    event: "AddToCart",
    data: {
      content_ids: [ticketBlock.id],
      content_name: ticketBlock.name,
      content_type: "product",
      value: ticketBlock.price + (ticketGroup.fee || 0),
      currency: "BRL",
    },
  }),
  initiate_checkout_shop: ({ amount, cart }) => ({
    event: "InitiateCheckout",
    data: {
      content_ids: [...Object.keys(cart)],
      num_items: Object.values(cart).reduce(
        (acc, { quantity }) => acc + quantity,
        0
      ),
      value: amount,
      currency: "BRL",
    },
  }),
  initiate_checkout: ({ amount, Tickets = [] }) => ({
    event: "InitiateCheckout",
    data: {
      content_ids: Tickets.map(({ id }) => id),
      num_items: Tickets.length,
      value: amount,
      currency: "BRL",
    },
  }),
  purchase: ({ amount, Tickets = [] }) => ({
    event: "Purchase",
    data: {
      content_ids: Tickets.map(({ id }) => id),
      num_items: Tickets.length,
      value: amount,
      currency: "BRL",
    },
  }),
};

export default {
  name: "YourComponentName",
  props: {
    fbPixelId: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    return {
      script: [
        {
          vmid: "fb-pixel",
          innerHTML: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${this.fbPixelId}');
            fbq('track', 'PageView');
          `,
          type: "text/javascript",
          charset: "utf-8",
        },
      ],
      __dangerouslyDisableSanitizersByTagID: {
        "fb-pixel": ["innerHTML"],
      },
    };
  },
  beforeDestroy() {
    this.removeFacebookPixel();
  },
  methods: {
    removeFacebookPixel() {
      if (window.fbq) {
        delete window.fbq;
        const fbPixelScript = document.querySelector(
          'script[src="https://connect.facebook.net/en_US/fbevents.js"]'
        );
        if (fbPixelScript) {
          fbPixelScript.remove();
        }
        const fbPixelNoScript = document.querySelector("noscript");
        if (
          fbPixelNoScript &&
          fbPixelNoScript.innerText.includes("https://www.facebook.com/tr")
        ) {
          fbPixelNoScript.remove();
        }
      }
    },
    emitEvent(eventName, data) {
      if (!window.fbq) return;
      window.fbq("track", eventName, data);
      console.log("Event sent to Facebook Pixel", eventName, data);
    },
    emit(type) {
      return (d) => {
        if (eventProcessor[type]) {
          const { event, data } = eventProcessor[type](d);
          this.emitEvent(event, data);
        }
      };
    },
  },
  mounted() {
    for (var event of Object.keys(eventProcessor)) {
      this.$root.$on(event, this.emit(event));
    }
  },
};
</script>

<style>
</style>