import http from "@/http-common";

const getOrders = async () => {
  return await http
    .get(`/app/products/orders`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getOrderDetails = async (orderId) => {
  return await http
    .get(`/app/products/orders/${orderId}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
}

export default {
  getOrders,
  getOrderDetails
};
