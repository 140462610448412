<template>
  <div
    class="card lightning / interactive masked active"
    @mousemove="handleMouseMove"
    data-number="160"
    data-set="swsh12pt5"
    data-subtypes="basic"
    data-supertype="pokémon"
    data-rarity="rare secret"
    data-trainer-gallery="false"
    :style="dynamicStyles"
    style="
      --card-opacity: 1;
      --rotate-x: 0deg;
      --rotate-y: 0deg;
      --card-scale: 1;
      --translate-x: 0px;
      --translate-y: 0px;
    "
  >
    <div class="card__translater">
      <div class="card__rotator" tabindex="0">
        <div
          class="card__front"
          :style="{
            backgroundColor,
          }"
          style="
            --seedx: 0.2513851865700316;
            --seedy: 0.3118782710923276;
            --cosmosbg: 184px 399px;
            --mask: url(/img/folks.png);
            --foil: url(/img/folks.png);
          "
        >
          <slot></slot>
          <div class="card__shine"></div>
          <div class="card__glare"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const x = Math.random();
    const y = Math.random();
    return {
      randomSeed: { x, y },
      absoluteOrientation: null,
      cosmosPosition: { x: 0, y: 0 },
      pointerPosition: {
        pointerX: 0.5,
        pointerY: 0.5,
      },
    };
  },

  computed: {
    dynamicStyles() {
      const pointerX = this.pointerPosition?.pointerX;
      const pointerY = this.pointerPosition?.pointerY;
      const pointerFromCenter = Math.min(
        1,
        Math.sqrt(Math.pow(pointerX - 0.3, 2) + Math.pow(pointerY - 0.3, 2))
      );
      return `
        --seedx: ${this.randomSeed?.x};
        --seedy: ${this.randomSeed?.y};
        --cosmosbg: ${this.cosmosPosition?.x}px ${this.cosmosPosition?.y}px;
        --pointer-x: ${pointerX * 100}%;
        --pointer-y: ${pointerY * 100}%;
        --pointer-from-center: ${pointerFromCenter};
        --pointer-from-top: ${pointerY};
        --pointer-from-left: ${pointerX};
        --background-x: ${this.convertRange(pointerX, [0, 1], [35, 65])}%;
        --background-y: ${this.convertRange(pointerY, [0, 1], [35, 65])}%;
      `;
    },
  },
  methods: {
    convertRange(value, r1, r2) {
      return ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0];
    },
    handleMouseMove(event) {
      const { offsetX, offsetY } = event;
      const { clientHeight, clientWidth } = event.target;
      const pointerX = offsetX / clientWidth;
      const pointerY = offsetY / clientHeight;

      this.pointerPosition = {
        pointerX,
        pointerY,
      };
    },
  },
  mounted() {
    if (window.AbsoluteOrientationSensor) {
      this.absoluteOrientation = new AbsoluteOrientationSensor({
        frequency: 60,
      });
      this.absoluteOrientation.addEventListener("reading", () => {
        const [x, y, z] = this.absoluteOrientation.quaternion;
        this.pointerPosition = {
          pointerX: Math.abs(x),
          pointerY: Math.abs(y),
        };
      });
      this.absoluteOrientation?.start();
    }
  },
  beforeDestroy() {
    if (this.absoluteOrientation) this.absoluteOrientation.stop();
  },
  props: {
    backgroundColor: { type: String },
  },
};
</script>

<style scoped>
.card__translater {
  display: grid;
  will-change: transform, box-shadow;
  transform-origin: center;
  -webkit-transform-origin: center;
  touch-action: none;
  width: 100%;
  height: 100%;
}
.card__front {
  width: 100%;
  height: 100%;
}

/*

  SECRET RARE (GOLD)

*/

/*

  SHINE LAYERS

*/

.card[data-rarity="rare secret"] .card__shine {
  --shift: 1px;
  --imgsize: cover;

  background-image: var(--glitter), var(--glitter),
    conic-gradient(
      var(--sunpillar-clr-4),
      var(--sunpillar-clr-5),
      var(--sunpillar-clr-6),
      var(--sunpillar-clr-1),
      var(--sunpillar-clr-4)
    ),
    radial-gradient(
      farthest-corner circle at var(--pointer-x) var(--pointer-y),
      hsla(150, 00%, 0%, 0.98) 10%,
      hsla(0, 0%, 95%, 0.15) 90%
    );

  background-size: var(--glittersize) var(--glittersize),
    var(--glittersize) var(--glittersize), cover, cover;
  background-position: 45% 45%, 55% 55%, center center, center center;
  background-blend-mode: soft-light, hard-light, overlay;
  mix-blend-mode: color-dodge;
  filter: brightness(calc(0.4 + (var(--pointer-from-center) * 0.2))) contrast(1)
    saturate(2.7);
}

.card[data-rarity="rare secret"] .card__shine:before {
  content: "";
  -webkit-mask-image: none !important;
  mask-image: none !important;

  background-image: var(--foil),
    linear-gradient(45deg, hsl(46, 95%, 50%), hsl(52, 100%, 69%)),
    radial-gradient(
      farthest-corner circle at var(--pointer-x) var(--pointer-y),
      hsla(10, 20%, 90%, 0.95) 10%,
      hsl(0, 0%, 0%) 70%
    );

  background-size: var(--imgsize), cover, cover;
  background-position: center center, center center, center center;
  background-blend-mode: hard-light, multiply;

  mix-blend-mode: lighten;
  filter: brightness(1.25) contrast(1.25) saturate(0.35);
  opacity: 0.8;
}

.card[data-rarity="rare secret"] .card__shine:after {
  content: "";

  -webkit-mask-image: none !important;
  mask-image: none !important;

  background-image: var(--glitter);
  background-size: var(--glittersize) var(--glittersize);
  background-position: calc(
      50% - ((var(--shift) * 2) * var(--pointer-from-left)) + var(--shift)
    )
    calc(50% - ((var(--shift) * 2) * var(--pointer-from-top)) + var(--shift));

  filter: brightness(calc((var(--pointer-from-center) * 0.6) + 0.6))
    contrast(1.5);
  mix-blend-mode: overlay;
}

/*

  GLARE LAYERS

*/

.card .card__glare {
  background-image: radial-gradient(
    farthest-corner circle at var(--pointer-x) var(--pointer-y),
    hsla(45, 8%, 80%, 0.3) 0%,
    hsl(22, 15%, 12%) 180%
  );

  filter: brightness(1.3) contrast(1.5);
  mix-blend-mode: hard-light;
}

.card {
  --grain: url("/img/grain.webp");
  --glitter: url("/img/glitter.png");
  --glittersize: 0%;

  --space: 5%;
  --angle: 133deg;
  --imgsize: cover;

  --red: #f80e35;
  --yellow: #eedf10;
  --green: #21e985;
  --blue: #0dbde9;
  --violet: #c929f1;

  --clip: inset(9.85% 8% 52.85% 8%);
  --clip-invert: polygon(
    0% 0%,
    100% 0%,
    100% 100%,
    0% 100%,
    0 47.15%,
    91.5% 47.15%,
    91.5% 9.85%,
    8% 9.85%,
    8% 47.15%,
    0 50%
  );

  --clip-stage: polygon(
    91.5% 9.85%,
    57% 9.85%,
    54% 12%,
    17% 12%,
    16% 14%,
    12% 16%,
    8% 16%,
    8% 47.15%,
    92% 47.15%
  );
  --clip-stage-invert: polygon(
    0% 0%,
    100% 0%,
    100% 100%,
    0% 100%,
    0 47.15%,
    91.5% 47.15%,
    91.5% 9.85%,
    57% 9.85%,
    54% 12%,
    17% 12%,
    16% 14%,
    12% 16%,
    8% 16%,
    8% 47.15%,
    0 50%
  );

  --clip-trainer: inset(14.5% 8.5% 48.2% 8.5%);
  --clip-borders: inset(2.8% 4% round 2.55% / 1.5%);
}

.card__shine,
.card__glare {
  will-change: transform, opacity, background-image, background-size,
    background-position, background-blend-mode, filter;
}
/*

  BASE STYLES / VARIABLES
*/

/*

  SHINE LAYERS

*/

:root {
  --card-aspect: 0.718;
  --card-radius: 4.55% / 3.5%;
  --card-edge: hsl(47, 100%, 78%);
  --card-back: hsl(205, 100%, 25%);
  --card-glow: hsl(175, 100%, 90%);

  --sunpillar-1: hsl(2, 100%, 73%);
  --sunpillar-2: hsl(53, 100%, 69%);
  --sunpillar-3: hsl(93, 100%, 69%);
  --sunpillar-4: hsl(176, 100%, 76%);
  --sunpillar-5: hsl(228, 100%, 74%);
  --sunpillar-6: hsl(283, 100%, 73%);

  --sunpillar-clr-1: var(--sunpillar-1);
  --sunpillar-clr-2: var(--sunpillar-2);
  --sunpillar-clr-3: var(--sunpillar-3);
  --sunpillar-clr-4: var(--sunpillar-4);
  --sunpillar-clr-5: var(--sunpillar-5);
  --sunpillar-clr-6: var(--sunpillar-6);
}

.card {
  pointer-events: none;
}

.card,
.card * {
  /* outline is a little trick to anti-alias */
  outline: 1px solid transparent;
}

.card.active .card__translater,
.card.active .card__rotator {
  /* prevent pinch/double-tap zooms on card */
  touch-action: none;
}

.card__translater,
.card__rotator {
  display: grid;
}

.card__translater {
  width: auto;
  position: relative;
}

.card__rotator {
  pointer-events: auto;
}

.card__rotator,
.card.active .card__rotator:focus {
  transition: box-shadow 0.4s ease, opacity 0.33s ease-out;
}

.card__rotator * {
  width: 100%;
  display: grid;
  grid-area: 1/1;
  aspect-ratio: var(--card-aspect);
  border-radius: var(--card-radius);
  image-rendering: optimizeQuality;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  pointer-events: none;
  overflow: hidden;
}

.card__front,
.card__front * {
  backface-visibility: hidden;
}

.card__front {
  opacity: 1;
  transition: opacity 0.33s ease-out;
  -webkit-transform: translate3d(0px, 0px, 0.01px);
  transform: translate3d(0px, 0px, 0.01px);
}

/**
  
Shine & Glare Effects

**/

.card__shine {
  display: grid;
  transform: translateZ(1px);
  overflow: hidden;
  z-index: 3;

  background: transparent;
  background-size: cover;
  background-position: center;

  filter: brightness(0.85) contrast(2.75) saturate(0.65);
  mix-blend-mode: color-dodge;

  opacity: var(--card-opacity);
}

.card__shine:before,
.card__shine:after {
  --sunpillar-clr-1: var(--sunpillar-5);
  --sunpillar-clr-2: var(--sunpillar-6);
  --sunpillar-clr-3: var(--sunpillar-1);
  --sunpillar-clr-4: var(--sunpillar-2);
  --sunpillar-clr-5: var(--sunpillar-3);
  --sunpillar-clr-6: var(--sunpillar-4);

  grid-area: 1/1;
  transform: translateZ(1px);
  border-radius: var(--card-radius);
}

.card__shine:after {
  --sunpillar-clr-1: var(--sunpillar-6);
  --sunpillar-clr-2: var(--sunpillar-1);
  --sunpillar-clr-3: var(--sunpillar-2);
  --sunpillar-clr-4: var(--sunpillar-3);
  --sunpillar-clr-5: var(--sunpillar-4);
  --sunpillar-clr-6: var(--sunpillar-5);

  transform: translateZ(1.2px);
}

.card__glare {
  /* make sure the glare doesn't clip */
  transform: translateZ(1.41px);
  overflow: hidden;

  background-image: radial-gradient(
    farthest-corner circle at var(--pointer-x) var(--pointer-y),
    hsla(0, 0%, 100%, 0.8) 10%,
    hsla(0, 0%, 100%, 0.65) 20%,
    hsla(0, 0%, 0%, 0.5) 90%
  );

  opacity: var(--card-opacity);
  mix-blend-mode: overlay;
}

.card.masked .card__shine,
.card.masked .card__shine:before,
.card.masked .card__shine:after {
  /** masking image for cards which are masked **/
  -webkit-mask-image: var(--mask);
  mask-image: var(--mask);
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-position: center center;
  mask-position: center center;
}

.card[data-rarity="rare secret"][data-set="swsh12pt5"][data-number="160"]
  .card__shine {
  --r-clr-1: hsl(0, 57%, 37%);
  --r-clr-2: hsl(40, 53%, 39%);
  --r-clr-3: hsl(90, 60%, 35%);
  --r-clr-4: hsl(180, 60%, 35%);
  --r-clr-5: hsl(210, 57%, 39%);
  --r-clr-6: hsl(294, 86%, 34%);
  --r-clr-7: hsl(280, 55%, 31%);

  background-image: linear-gradient(-45deg, var(--r-clr-1), var(--r-clr-5)),
    var(--glitter),
    linear-gradient(
      -30deg,
      var(--r-clr-1),
      var(--r-clr-2),
      var(--r-clr-3),
      var(--r-clr-4),
      var(--r-clr-5),
      var(--r-clr-6),
      var(--r-clr-7),
      var(--r-clr-1),
      var(--r-clr-2),
      var(--r-clr-3),
      var(--r-clr-4),
      var(--r-clr-5),
      var(--r-clr-6),
      var(--r-clr-7),
      var(--r-clr-1),
      var(--r-clr-2),
      var(--r-clr-3),
      var(--r-clr-4),
      var(--r-clr-5),
      var(--r-clr-6),
      var(--r-clr-7),
      var(--r-clr-1)
    );

  background-blend-mode: luminosity, soft-light;

  background-size: 200% 200%, var(--glittersize) var(--glittersize), 400% 400%;

  background-position: calc(25% + (50% * var(--pointer-from-left)))
      calc(25% + (50% * var(--pointer-from-top))),
    calc(50% - ((var(--shift) * 2) * var(--pointer-from-left)) + var(--shift))
      calc(50% - ((var(--shift) * 2) * var(--pointer-from-top)) + var(--shift)),
    calc(25% + (var(--pointer-x) / 2)) calc(25% + (var(--pointer-y) / 2));

  filter: brightness(calc((var(--pointer-from-center) * 0.5) + 0.75))
    contrast(2) saturate(1);
}

.card[data-rarity="rare secret"][data-set="swsh12pt5"][data-number="160"]
  .card__shine:after {
  content: "";

  -webkit-mask-image: none !important;
  mask-image: none !important;

  background-image: var(--glitter),
    linear-gradient(
      -60deg,
      var(--r-clr-1),
      var(--r-clr-2),
      var(--r-clr-3),
      var(--r-clr-4),
      var(--r-clr-5),
      var(--r-clr-6),
      var(--r-clr-7),
      var(--r-clr-1),
      var(--r-clr-2),
      var(--r-clr-3),
      var(--r-clr-4),
      var(--r-clr-5),
      var(--r-clr-6),
      var(--r-clr-7),
      var(--r-clr-1),
      var(--r-clr-2),
      var(--r-clr-3),
      var(--r-clr-4),
      var(--r-clr-5),
      var(--r-clr-6),
      var(--r-clr-7),
      var(--r-clr-1)
    );

  background-blend-mode: soft-light;

  background-size: var(--glittersize) var(--glittersize), 400% 400%;

  background-position: calc(
        50% - ((var(--shift) * 2) * var(--pointer-from-left)) - var(--shift)
      )
      calc(50% - ((var(--shift) * 2) * var(--pointer-from-top)) - var(--shift)),
    var(--pointer-x) var(--pointer-y);

  filter: brightness(calc((var(--pointer-from-center) * 0.35) + 0.35))
    contrast(2) saturate(1);
  mix-blend-mode: exclusion;
}

.card[data-rarity="rare secret"][data-set="swsh12pt5"][data-number="160"]
  .card__shine:before {
  content: "";

  background-image: var(--foil);
  background-size: var(--imgsize);
  background-position: center center;
  filter: brightness(2.5) contrast(1);

  opacity: calc((var(--pointer-from-center) + 0.4) * 0.6);
  mix-blend-mode: multiply;
}
.card[data-rarity="rare secret"][data-set="swsh12pt5"][data-number="160"]
  .card__glare {
  background-image: radial-gradient(
    farthest-corner circle at var(--pointer-x) var(--pointer-y),
    hsl(0, 0%, 80%),
    hsla(0, 0%, 74.9%, 0.25) 30%,
    hsl(0, 0%, 21.6%) 130%
  );

  filter: brightness(0.9) contrast(2);
  opacity: calc(var(--pointer-from-center) * 0.9);
  mix-blend-mode: hard-light;
}
</style>
