import axios from 'axios';
const { VUE_APP_SERVER } = process.env;

export default async function getIp() {
  const sources = [
    'https://api.ipify.org?format=json',
    'https://jsonip.com',
    `${VUE_APP_SERVER}/api/ip`,
  ];

  for (const source of sources) {
    try {
      const response = await axios.get(source);
      return response.data;
    } catch (error) {
      // Ignorar erro e tentar a próxima fonte
    }
  }

  throw new Error('Não foi possível obter as informações necessárias do dispositivo!');
}
